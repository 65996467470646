import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ProposalProvider } from './Contexts/Proposals';
import Routes from './routes';

function App() {
  return (
    <ProposalProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ProposalProvider>
  );
}

export default App;

import { Redirect, Route } from 'react-router-dom';
import { getToken } from './services/auth';

export default function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        !getToken() ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      )}
    />
  );
}

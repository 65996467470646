import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://9ec1y54oca.execute-api.us-east-1.amazonaws.com/dev',
  // baseURL: 'http://localhost:3000/dev',
  validateStatus: (status) => status < 500,
});

api.interceptors.request.use((req) => {
  req.headers.Authorization = getToken();
  return req;
});

export default api;

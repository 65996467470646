import './style.css';
import { FormLogin } from '../../components/Form';

function loginPage() {
  return (
    <section id="login">
      <FormLogin />
    </section>
  );
}

export default loginPage;

export function saveToken(token) {
  const date = new Date();
  const validateMinutes = 60 * 3;

  date.setTime(date.getTime() + 1000 * 60 * validateMinutes);

  document.cookie = `authToken=${token};expires=${date.toGMTString()};path=/`;
}

export function getToken() {
  let cookies = decodeURIComponent(document.cookie);
  cookies = cookies.split(';');
  cookies = cookies.filter((c) => {
    let cookie = c;
    cookie = cookie.split('=');
    if (cookie[0].trim() === 'authToken') {
      return cookie[1];
    }

    return null;
  });

  if (cookies[0]) {
    const token = cookies[0].split('=')[1];
    return token;
  }

  return undefined;
}

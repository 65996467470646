import React from 'react';
import { Input } from 'antd';
import MaskedInput from 'react-input-mask';

// import './styles.css';

export default function InputMask({
  mask, id, value, onChange, placeholder, disabled, required, size,
  inputMode = 'default', prefix = null,

}) {
  return (
    <MaskedInput
      mask={mask}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      maskChar={null}
      disabled={disabled}
      required={required}
      id={id}
    >
      {() => (
        <Input
          size="large"
          prefix={prefix}
          inputMode={inputMode}
          id={id}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
    </MaskedInput>
  );
}

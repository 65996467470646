import React, {
  createContext, useState, useContext, useEffect,
} from 'react';

export const ProposalsContext = createContext();

export function ProposalProvider({ children }) {
  const [clients, setClients] = useState([]);
  const [clientSelected, setClientSelected] = useState();
  const [proposals, setProposals] = useState([]);
  const [idProposalSelected, setIdProposalSelected] = useState();
  const [proposalSelected, setProposalSelected] = useState();
  const [search, setSearch] = useState('');
  const [cardsVindi, setCardsVindi] = useState([]);

  useEffect(() => {
    setProposalSelected(proposals.find((p) => p.idProposta === Number(idProposalSelected)));
  }, [idProposalSelected]);

  return (
    <ProposalsContext.Provider
      value={{
        clients,
        setClients,
        clientSelected,
        setClientSelected,
        proposals,
        setProposals,
        proposalSelected,
        setProposalSelected,
        idProposalSelected,
        setIdProposalSelected,
        search,
        setSearch,
        cardsVindi,
        setCardsVindi,
      }}
    >
      {children}
    </ProposalsContext.Provider>
  );
}

export function useProposalsContext() {
  const context = useContext(ProposalsContext);
  if (!context) throw new Error('useProposalsContext está fora de contexto');
  const {
    clients,
    setClients,
    clientSelected,
    setClientSelected,
    proposals,
    setProposals,
    proposalSelected,
    setProposalSelected,
    search, setSearch,
    idProposalSelected,
    setIdProposalSelected,
    cardsVindi,
    setCardsVindi,
  } = context;

  return {
    clients,
    setClients,
    clientSelected,
    setClientSelected,
    proposals,
    setProposals,
    proposalSelected,
    setProposalSelected,
    search,
    setSearch,
    idProposalSelected,
    setIdProposalSelected,
    cardsVindi,
    setCardsVindi,
  };
}

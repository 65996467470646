import './style.css';
import logo from '../../assets/logo_planeta_energia.svg';

export function Header() {
  if (window.location.pathname === '/') return null;
  return (
    <header>
      <div className="header-top">
        <div>
          <img src={logo} alt="logo" title="logo" />
        </div>
      </div>
    </header>
  );
}

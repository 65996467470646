import React, { useEffect, useState } from 'react';
import './style.css';
import { Input, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import { Title } from '../../components/Title';
import { useProposalsContext } from '../../Contexts/Proposals';
import { Header } from '../../components/Header/Header';

export function Painel({ history }) {
  const [load, setLoad] = useState(false);
  const [loadProposal, setLoadProposal] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);

  const { Option } = Select;

  const {
    search,
    setSearch,
    clients,
    setClients,
    clientSelected,
    setClientSelected,
    proposals,
    setProposals,
    proposalSelected,
    idProposalSelected,
    setIdProposalSelected,
    setCardsVindi,
  } = useProposalsContext();

  const searchClients = async () => {
    if (!search) {
      alert('Digite o CPF ou nome do cliente para busca'); // eslint-disable-line
      return;
    }

    if (String(search).length < 5) {
      alert('Busca precisa de no minímo 5 caracteres'); // eslint-disable-line
      return;
    }

    try {
      setLoad(true);
      const { data, status } = await api.get(`/clientes?cpfNome=${search}`);

      if (status === 401) {
        history.push('/');
        setLoad(false);
        return;
      }

      if (status === 200) {
        setClients(data.clientes);
        setClientSelected(null);
        setIdProposalSelected(null);
        setProposals([]);
        setFirstSearch(false);
      }
      setLoad(false);
    } catch (err) {
      setLoad(false);
      alert('Houve um erro inesperado, tente novamente'); // eslint-disable-line
    }
  };

  useEffect(() => {
    (async () => {
      if (clientSelected) {
        setFirstSearch(false);
        setLoadProposal(true);

        const { data, status } = await api.get(`/clientes/${clientSelected}/propostas`);
        if (status === 401) {
          history.push('/');
          setLoadProposal(false);
          return;
        }

        if (status === 200) {
          setProposals(data.propostas);
          setCardsVindi(data.propostas.map((p) => p.idCartaoVind));
        }
        setLoadProposal(false);
      }
    })();
  }, [clientSelected, clients]);

  return (
    <>
      <Header />
      <section id="painel">
        <Title title="Pesquisar Cliente" />
        <div className="search-wrapper">
          <Input
            type="text"
            placeholder="Busca CPF"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={(e) => e.target.select()}
          />
          {/* <button
            type="button"
            onClick={() => {
              setClients([]);
              setClientSelected(null);
              setIdProposalSelected(null);
              setProposals([]);
              setSearch('');
            }}
            disabled={load}
          >
            {load ? (
              <FontAwesomeIcon className="searchIcon" icon={faSpinner} spin />
            ) : (
              <CloseOutlined />
            )}
          </button> */}
          <button type="button" onClick={searchClients} disabled={load}>
            {load ? (
              <FontAwesomeIcon className="searchIcon" icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon className="searchIcon" icon={faSearch} />
            )}
          </button>

        </div>

        {/* <p id="error">{error}</p> */}

        <div className="options-client">
          <Select
            size="large"
            value={clientSelected}
            onChange={setClientSelected}
            placeholder="Lista de Clientes"
            notFoundContent={<p>Nenhum Cliente encontrado</p>}
            loading={load}
          >
            {clients.length === 1 ? (
              <Option value={clients[0].id} selected>{clients[0].nome}</Option>
            ) : (
              <>
                {clients.map((c) => (
                  <Option value={c.id} selected>{c.nome}</Option>
                ))}
              </>
            )}
          </Select>
        </div>

        <div className="options-client">
          <Select
            size="large"
            placeholder="Lista de Propostas"
            onChange={setIdProposalSelected}
            value={idProposalSelected}
            loading={loadProposal}
            notFoundContent={<p>Nenhuma proposta encontrada</p>}
          >
            {proposals.map((p) => (
              <Option value={p.idProposta}>{p.nomeEstipulante}</Option>
            ))}
          </Select>
        </div>

        <div className="proposal">
          <button
            type="button"
            disabled={!proposalSelected || loadProposal}
            onClick={() => history.push('/alteraproposta')}
          >
            Alterar Proposta
          </button>
          <button
            type="button"
            disabled={firstSearch || loadProposal}
            onClick={() => history.push('/novaproposta')}
          >
            Nova Proposta
          </button>
        </div>
      </section>
    </>
  );
}

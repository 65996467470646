import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import { Painel } from './pages/Painel';
import { NewProposal } from './pages/NovaProposta';
import { changeProposal } from './pages/AlterarProposta';
import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/painel" component={Painel} />
      <PrivateRoute exact path="/novaproposta" component={NewProposal} />
      <PrivateRoute exact path="/alteraproposta" component={changeProposal} />
    </Switch>
  </BrowserRouter>
);

export default Routes;

import React, { useState, useEffect } from 'react';
import './style.css';
import axios from 'axios';
import { Select, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Title } from '../../components/Title';
import { useProposalsContext } from '../../Contexts/Proposals';
import api from '../../services/api';
import { getBrand, treatDate, treatStrings } from '../../utils/utils';
import InputMask from '../../components/InputMask';
import { Header } from '../../components/Header/Header';

export function NewProposal({ history }) {
  const {
    clients,
    setClients,
    clientSelected,
    setClientSelected,
    setProposals,
    setProposalSelected,
    setSearch,
    setIdProposalSelected,
    cardsVindi,
    setCardsVindi,
  } = useProposalsContext();

  const [clientName, setClientName] = useState('');
  const [clientCpf, setClientCpf] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientBirthDate, setClientBirthDate] = useState('');
  const [premiumValue, setPremiumValue] = useState();
  const [billingAgreement, setBillingAgreement] = useState([]);
  const [idBillingSelected, setIdBillingSelected] = useState();
  const [needCard, setNeedCard] = useState(false);
  const [obs, setObs] = useState('');

  const [nameCard, setNameCard] = useState('');
  const [numberCard, setNumberCard] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');

  const [load, setLoad] = useState(false);
  const [loadBilling, setLoadBilling] = useState(false);
  const [registeredCards, setRegisteredCards] = useState([]);
  const [registeredCardSelected, setRegisteredCardSelected] = useState();
  const [selectCard, setSelectCard] = useState(1);

  const { Option } = Select;

  useEffect(() => {
    (async () => {
      setLoadBilling(true);
      const { data, status } = await api.get('/convenios');
      if (status === 200) setBillingAgreement(data.convenios);
      setLoadBilling(false);
      const biils = [];
      for await (const c of cardsVindi) {
        if (c) {
          const { data, status } = await api.get(`/clientes/perfil_pagamento/${c}`); // eslint-disable-line       
          if (status === 401) {
            history.push('/');
            return;
          }

          if (data.cartao.bandeira) {
            biils.push({
              id: Number(c), // todo: perguntar id e não repete cartão
              convenio: `${data.cartao.bandeira} Final: ${data.cartao.finalCartao}`,
              cartao: false,
              idClienteVindi: data.cartao.idCliente,
            });
          }
        }
      }

      setRegisteredCards(biils);
      if (biils.length > 0) setRegisteredCardSelected(biils[0].id);
    })();

    if (clientSelected) {
      const client = clients.find((c) => c.id === clientSelected);
      if (client) {
        setClientName(client.nome);
        setClientCpf(client.cpf);
        setClientBirthDate(client.dataNasc);
      }
    }
    return () => {
      setCardsVindi([]);
    };
  }, []);

  useEffect(() => {
    const billingSelected = billingAgreement.find((b) => b.id === Number(idBillingSelected));
    if (billingSelected)setNeedCard(billingSelected.cartao);
  }, [idBillingSelected]);

  const handleSubmit = async () => {
    try {
      if (!clientName) {
        alert('Diga o nome do cliente'); // eslint-disable-line
        return;
      }

      if (!clientCpf) {
        alert('Diga o CPF do cliente'); // eslint-disable-line
        return;
      }

      if (!clientBirthDate) {
        alert('Diga a data de nascimento do cliente'); // eslint-disable-line
        return;
      }

      if (!premiumValue) {
        alert('Diga o prêmio da proposta'); // eslint-disable-line
        return;
      }

      if (!idBillingSelected) {
        alert('Diga o convênio de cobrança'); // eslint-disable-line
        return;
      }

      let gatewayToken;
      if (needCard) {
        if (selectCard === 1) {
          if (!nameCard) {
          alert('Diga o nome no cartão'); // eslint-disable-line
            return;
          }

          if (!numberCard) {
          alert('Diga o número do cartão'); // eslint-disable-line
            return;
          }

          if (!expirationDate) {
          alert('Diga a data de expiração do cartão'); // eslint-disable-line
            return;
          }

          if (!cvv) {
          alert('Diga o código de segurança do cartão'); // eslint-disable-line
            return;
          }

          setLoad(true);

          const { data, status } = await axios.post('https://app.vindi.com.br/api/v1/public/payment_profiles', {
            holder_name: nameCard,
            card_expiration: expirationDate,
            allow_as_fallback: true,
            card_number: treatStrings(numberCard),
            card_cvv: cvv,
            payment_method_code: 'credit_card', //
            payment_company_code: getBrand(numberCard),
          }, {
            auth: {
              username: 'VKFwF4-7wX5_Ibs7VFBVvzrMlEMtcq9mzwPccjMkXMU',
              password: '',
            },
          });

          if (status === 201) {
            gatewayToken = data.payment_profile.gateway_token;
          } else {
            setLoad(false);
            alert('Houve um erro inesperado com o vindi, tente novamente') // eslint-disable-line
            return;
          }
        } else if (selectCard === 2) {
          if (!registeredCardSelected) {
            alert('Diga o cartão'); // eslint-disable-line
            return;
          }

          const card = registeredCards.find((r) => r.id === registeredCardSelected);
          setLoad(true);

          const { status } = await api.post('/clientes/propostas', {
            metodoPagamento: 3,
            nome: clientName,
            cpf: treatStrings(clientCpf),
            email: clientEmail,
            dtNasc: treatDate(clientBirthDate),
            valorPremio: String(premiumValue).replace(',', '.'),
            idConvenio: idBillingSelected,
            gatewayToken: null,
            idClienteVindi: card.idClienteVindi,
            idCartaoVindi: registeredCardSelected,
            obs,
          });

          if (status !== 200) {
            alert('Houve um erro inesperado, tente novamente'); // eslint-disable-line
            setLoad(false);
            return;
          }

          alert('Proposta cadastrada com sucesso'); // eslint-disable-line
          setLoad(false);
          setClients([]);
          setClientSelected(null);
          setProposals([]);
          setProposalSelected(null);
          setIdProposalSelected(null);
          setSearch('');
          history.push('/painel');
          return;
        }
      }
      setLoad(true);

      const { status } = await api.post('/clientes/propostas', {
        metodoPagamento: needCard && selectCard === 1 ? 2 : 1,
        nome: clientName,
        cpf: treatStrings(clientCpf),
        email: clientEmail,
        dtNasc: treatDate(clientBirthDate),
        valorPremio: String(premiumValue).replace(',', '.'),
        idConvenio: idBillingSelected,
        gatewayToken: needCard && selectCard === 1 ? gatewayToken : null,
        obs,
      });

      if (status !== 200) {
        alert('Houve um erro inesperado, tente novamente'); // eslint-disable-line
        setLoad(false);
        return;
      }

      alert('Proposta salva com sucesso'); // eslint-disable-line
      setLoad(false);
      setSearch('');
      setClients([]);
      setClientSelected(null);
      setProposals([]);
      setProposalSelected(null);
      setIdProposalSelected(null);
      history.push('/painel');
    } catch (error) {
      setLoad(false);
      alert('Houver um erro inesperado, tente novamente'); // eslint-disable-line
    }
  };

  return (
    <>
      <Header />
      <section id="proposal">
        <div className="new-proposal-wrapper">
          <Title title="Nova Proposta" />

          <Input
            type="text"
            id="input"
            size="large"
            placeholder="Nome do Cliente"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />

          <InputMask
            type="text"
            mask="999.999.999-99"
            id="input"
            size="large"
            placeholder="CPF do Cliente"
            inputMode="numeric"
            value={clientCpf}
            onChange={(e) => setClientCpf(e.target.value)}
          />

          <Input
            type="email"
            id="input"
            size="large"
            placeholder="Email do Cliente"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />

          <InputMask
            type="text"
            mask="99/99/9999"
            inputMode="numeric"
            id="input"
            size="large"
            placeholder="Data de Nascimento do Cliente"
            value={clientBirthDate}
            onChange={(e) => setClientBirthDate(e.target.value)}
          />

          <Input
            prefix="R$"
            type="text"
            size="large"
            placeholder="Valor do Prêmio"
            inputMode="decimal"
            value={premiumValue}
            onChange={(e) => setPremiumValue(e.target.value)}
          />

          <Select
            value={idBillingSelected}
            onChange={setIdBillingSelected}
            placeholder="Convênios de Cobranças"
            size="large"
            loading={loadBilling}
          >
            {billingAgreement.map((b) => (
              <Option value={b.id} key={b.id}>{b.convenio}</Option>
            ))}
          </Select>

          <Input
            size="large"
            id="input"
            placeholder="Observação"
            type="text"
            value={obs}
            onChange={(e) => setObs(e.target.value)}
          />

          {needCard && (
          <>
            {registeredCards.length > 0 && (
            <>
              <Select
                value={selectCard}
                onChange={setSelectCard}
                placeholder="Cartões"
                size="large"
                loading={loadBilling}
              >
                <Option value={1}>Novo cartão</Option>
                <Option value={2}>Cartão já cadastrado</Option>
              </Select>

              {selectCard === 2 && (
              <Select
                value={registeredCardSelected}
                onChange={setRegisteredCardSelected}
                placeholder="Cartões Cadastrado"
                size="large"
                loading={loadBilling}
              >
                {registeredCards.map((b) => (
                  <Option value={b.id} key={b.id}>{b.convenio}</Option>
                ))}
              </Select>
              )}

              {selectCard === 1 && (
              <>
                <Input
                  id="input"
                  size="large"
                  placeholder="Nome no Cartão"
                  type="text"
                  value={nameCard}
                  onChange={(e) => setNameCard(e.target.value)}
                />

                <InputMask
                  id="input"
                  size="large"
                  placeholder="Número Cartão"
                  type="text"
                  value={numberCard}
                  onChange={(e) => setNumberCard(e.target.value)}
                  mask="9999 9999 9999 9999"
                  inputMode="numeric"
                />

                <InputMask
                  id="input"
                  size="large"
                  placeholder="Data de Expiração"
                  type="text"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  mask="99/99"
                  inputMode="numeric"
                />

                <InputMask
                  id="input"
                  size="large"
                  placeholder="CVV"
                  type="text"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  mask="999"
                  inputMode="numeric"
                />

              </>
              )}
            </>
            )}
            {registeredCards.length < 1 && (
            <>
              <Input
                id="input"
                size="large"
                placeholder="Nome no Cartão"
                type="text"
                value={nameCard}
                onChange={(e) => setNameCard(e.target.value)}
              />

              <InputMask
                id="input"
                size="large"
                placeholder="Número Cartão"
                type="text"
                value={numberCard}
                onChange={(e) => setNumberCard(e.target.value)}
                mask="9999 9999 9999 9999"
                inputMode="numeric"
              />

              <InputMask
                id="input"
                size="large"
                placeholder="Data de Expiração"
                type="text"
                value={expirationDate}
                onChange={(e) => setExpirationDate(e.target.value)}
                mask="99/99"
                inputMode="numeric"
              />

              <InputMask
                id="input"
                size="large"
                placeholder="CVV"
                type="text"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                mask="999"
                inputMode="numeric"
              />

            </>
            )}
          </>
          )}

        </div>

        <div className="proposal-buttons">
          <button type="button" onClick={() => history.push('/painel')}>Voltar</button>
          <button type="submit" onClick={handleSubmit}>
            {load ? (
              <FontAwesomeIcon className="searchIcon" icon={faSpinner} spin />
            ) : (
              'Salvar'
            )}
          </button>
        </div>
      </section>
    </>
  );
}

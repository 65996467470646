import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faEye } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import logo from '../../assets/logo_planeta_energia.svg';
import api from '../../services/api';
import { saveToken } from '../../services/auth';

import './style.css';

export function FormLogin() {
  const { push } = useHistory();
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');

  function showPass() {
    const x = document.querySelector('#login > div > div > form > div:nth-child(3) > input');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoad(true);
      const { data, status } = await api.post('/login', {
        usuario: user,
        senha: pass,
      });

      if (status === 401) {
        setError('Usuário ou senha inválidos');
        setLoad(false);
        return;
      }

      if (status !== 200) {
        console.log(status);
        setError('houve um erro inesperado');
        setLoad(false);
        return;
      }

      saveToken(data.token);
      setLoad(false);
      push('/painel');
    } catch (err) {
      setError('houve um erro inesperado');
      setLoad(false);
    }
  }

  return (
    <div className="form-container">
      <div className="sign-in">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <img src={logo} alt="logo" />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Digite seu usuário"
              required="required"
              className="form-control"
              autoComplete="off"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Senha"
              required="required"
              className="form-control"
              autoComplete="off"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
            <FontAwesomeIcon className="eyeicon" icon={faEye} onClick={showPass} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn-send" title="Entrar">
              {load ? (
                <FontAwesomeIcon className="searchIcon" icon={faSpinner} spin />
              ) : (
                'Entrar'
              )}
            </button>
          </div>
          <p className="error">{error}</p>
        </form>
      </div>
    </div>
  );
}
